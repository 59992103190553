@media (max-width: 992px) {
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.2rem;
  }

  .home-about {
    .container {
      max-width: 100%;
    }
  }

  .home-about__img {
    width: 50%;
  }

  .home-about__info {
    width: 45%;
  }

  .home-about__flower {
    right: -5%;
    bottom: -5%;
    width: 170px;
  }

  .btn {
    font-size: 0.9rem;
  }

  .footer-social {
    padding-top: 20%;
    padding-bottom: 70px;
  }

  .footer-menu__item {
    margin: 5px 10px;
  }

  .footer__bottom {
    margin-top: 0;
    padding: 10px 0;
    font-size: 0.7rem;
  }

  .slider-detail--page .slider-detail__title {
    font-size: 4vw;
  }

  .article-page .article-box .text {
    overflow: visible;
    padding-right: 0;
    max-height: inherit;
  }

  .icon-list {
    li {
      margin: 2%;
      padding: 20px 15px 10px;
      width: 29%;
      border: 1px solid #dfdfdf !important;
      border-radius: 16px;

      svg {
        width: 28px;
        height: 28px;
      }
    }

    &__title {
      font-size: 0.9rem;
    }
  }

  .explore__nav {
    overflow-x: scroll;
    margin-bottom: 30px;
    padding-bottom: 15px;
    width: 100%;

    .tab-nav {
      flex-wrap: nowrap;
      width: 1000px;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 2px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $colorBlack;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: $colorBlack;
    }
  }

  .explore__article {
    width: 100%;
    padding: 15px;
  }

  .explore__nav .tab-link {
    margin: 0 15px 0 0;
    width: 110px;
    box-shadow: none !important;
    border: 1px solid #dfdfdf;
  }

  .explore__icon {
    height: 40px;
  }

  .explore__nav .tab-link {
    font-size: 0.8rem;
  }

  .route__title img{
    width: 20px;
  }

  .route__title{
    font-size: 1rem;
  }

  .route__detail{
    font-size: .9rem;
  }

  .route__title span{
    margin-left: 5px;
  }
}
