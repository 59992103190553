$colorOrange: #E44534;
$colorWedge: #ee0000;
$colorBlack: rgba(0, 0, 0, .1);
$colorBlack-2: rgba(0, 0, 0, .2);
$colorBlack-3: rgba(0, 0, 0, .3);
$colorBlack-4: rgba(0, 0, 0, .4);
$colorWhite: rgba(255, 255, 255, .1);
$colorWhite-2: rgba(255, 255, 255, .2);
$colorWhite-3: rgba(255, 255, 255, .3);
$colorWhite-4: rgba(255, 255, 255, .4);
$colorWhite-8: rgba(255, 255, 255, .8);

.color--wedge{
  color: #ee0000;
}

.color--white{
  color: #fff;
}

.color--orange{
  color: $colorOrange;
}
