.btn-mobil {
  padding: 0;
  position: relative;
  top: 4px;
  outline: transparent;
}

.arka-active {
  display: block;
  opacity: 1;
}

.mobil-nav--icon {
  display: block;
  height: 25px;
}

.mobil {
  display: block;
  position: fixed;
  top: 0;
  left: -320px;
  background: #fff;
  color: #343434;
  width: 300px;
  height: 100%;
  max-width: calc(100% - 80px);
  transition: left 0.2s;
  z-index: 9999;

  strong {
    font-weight: 500;
  }

  .nav-tabs {
    display: flex;
    position: absolute;
    bottom: 0;
    font-weight: 500;
    background: #f4f4f4;
    width: 100%;
    z-index: 99999;

    li {
      width: 50%;
      text-align: center;

      svg {
        margin-right: 4px;
      }
    }
  }

  .tab {
    overflow: hidden;
  }

  .iletisim-bilgileri {
    padding: 30px;
    line-height: 1.7;

    .iletisim-box {
      font-size: 0.85rem;
      padding-left: 0;
    }

    span {
      opacity: 0.7;
    }

    a {
      color: $colorOrange;
      text-decoration: underline;
    }
  }

  .tablinks {
    display: block;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 18px 0 15px;
    border-bottom: 3px solid transparent;
    opacity: 0.6;
    transition: all 0.3s;

    &:hover {
      border-color: $colorOrange;
      opacity: 0.8;
      color: $colorOrange;
    }

    &.active {
      border-color: $colorOrange;
      opacity: 1;
      color: $colorOrange;
    }
  }

  .tabcontent {
    padding-bottom: 45px;
    overflow-y: scroll;
    height: calc(100vh - 75px);
  }
}

.mobile-logo {
  padding: 20px 0;
  border-bottom: 1px solid #dfdfdf;

  img {
    display: block;
    margin: 0 auto;
    width: 100px;
  }
}

.mobil-menu-li {

  .btn-atik {
    display: block;
    padding: 15px 30px;
    width: auto;
    margin: 0 auto !important;
    width: 230px !important;
    margin-top: 30px !important;
  }
}

.mobil-menu-link {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #343434;
  padding: 15px 15px 15px 30px;
  transition: all 0.3s;
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;

  svg {
    width: 10px;
    height: 10px;
    color: #7d7d7d;
  }

  i {
    margin-right: 6px;
  }

  &:focus,
  &:hover {
    background: rgba($colorOrange, .2);
    color: $colorOrange;
  }

  &.active {
    background: rgba($colorOrange, .2);
    color: $colorOrange;
  }
}

.mobil-alt-menu {
  display: none;
  background: rgba(250, 218, 214, .3);
  padding: 15px 0;

  ul {
    padding: 10px 0;
    line-height: 1.4;
  }

  a {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    color: #343434;
    padding: 8px 8px 8px 40px;
    text-decoration: none;
    @include efekt(color);

    &:Hover {
      color: $colorOrange;
    }

    &.active {
      color: $colorOrange;
    }
  }
}

.mobil-active {
  left: 0;
}

.mobil-tab {
  height: calc(100vh - 56px);
  overflow-y: scroll;
}
