@media (max-width:1200px) {
  .menu {
    display: none;
  }

  .btn-mobil {
    display: inline-block;
  }

  .header {
    padding-right: 15px;
  }

  .slider-detail__text {
    width: 80%;
    font-size: 0.9rem;
  }

  .home-slider {
    .swiper-slide {
      img {
        height: 500px;
        object-fit: cover;
      }
    }
  }

  .slider-detail__link {
    font-size: 1rem;
  }

  .site-card__title {
    font-size: 1.1rem;
  }

  .text {
    font-size: 0.9rem;
  }

  .text--bigger {
    font-size: 1rem;
  }

  .footer-menu {
    margin-top: 25px;

    &__list {
      justify-content: center;
      text-align: center;
    }

    &__item {
      margin: 15px;
    }
  }

  .footer-logo {
    margin-right: auto;
    margin-left: auto;
    width: 135px;
  }

  .explore__nav{
    width: 280px;
  }

  .explore__article{
    width: calc(100% - 310px);
  }

  .explore__icon{
    height: 50px;
  }

  .explore__nav .tab-link{
    padding: 10px;
    font-size: .9rem;
  }
  .route__detail{
    width: 100%;
    display: block;
    margin-top: 15px;
  }

  .blog{
    padding-bottom: 20px;
  }

  .blog__title{
    font-size: 1rem;
  }

  .blog__date{
    font-size: .8rem;
    margin-top: 5px;
  }

  .blog__link{
    margin-top: 5px;
  }

  .blog__bottom{
    padding: 0;
    margin-top: 15px;
    position: relative;
  }

  .blog-title{
    font-size: 1.2rem;

    &--big{
      font-size: 1.1rem;
    }
  }
}
