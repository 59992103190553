.title {
  font-size: 3rem;
  font-weight: 900;
  display: block;
  line-height: 1.2;

  &--medium {
    font-size: 2.5rem;
  }

  &--smaller {
    font-size: 2rem;
  }

  small{
    font-size: 50%;
    display: block;
    font-weight: 300;
  }
}
@media (max-width:1200px) {
  .title {
    font-size: 2.7rem;

    &--medium {
      font-size: 2.3rem;
    }

    &--smaller {
      font-size: 1.7rem;
    }
  }
}
@media (max-width: 992px) {
  .title {
    font-size: 2.4rem;

    &--medium {
      font-size: 2rem;
    }

    &--smaller {
      font-size: 1.4rem;
    }
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 2.1rem;

    &--medium {
      font-size: 1.7rem;
    }

    &--smaller {
      font-size: 1.2rem;
    }
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 1.9rem;

    &--medium {
      font-size: 1.5rem;
    }

    &--smaller {
      font-size: 1.2rem;
    }
  }
}
