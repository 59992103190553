@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'swiper', 'typography', 'buttons';

.btn-mobil,
.mobil {
  display: none;
}

.arama-bg,
.mobile-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $colorBlack;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.arka-active {
  display: block;
}

.swiper-slide {
  img {
    width: 100%;
  }
}

.header {
  @include efekt(background);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  padding-left: 30px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 100%);

  &--flex {
    @extend .flex;
  }

  &--fixed {
    @extend .bxs;
    background: #fff;

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $colorWedge;
    }

    .logo {
      img {
        width: 77px;
      }
    }

    .menu {
      border: none;
    }

    .menu__link {
      color: $colorWedge;

      &:after {
        height: 2px;
      }
    }
  }

  &--page {
    @extend .bxs;
    background: #fff;

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $colorWedge;
    }

    .logo {
      img {
        width: 77px;
      }
    }

    .menu {
      border: none;
    }

    .menu__link {
      color: $colorWedge;

      &:after {
        height: 2px;
      }
    }
  }
}

.logo {
  display: inline-block;

  img {
    @include efekt(width);
  }
}

.menu {
  padding-right: 30px;
  border-bottom: 1px solid $colorWhite-2;

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__link {
    @include efekt(color);
    position: relative;
    display: block;
    padding: 30px 0;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;

    &:after {
      @include efekt(opacity);
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $colorOrange;
      content: '';
      opacity: 0;
    }

    &.active,
    &:hover {
      color: $colorOrange;

      &:after {
        opacity: 1;
      }
    }
  }

  .bi {
    margin-left: 2px;
    width: 8px;
    height: 8px;
  }
}

.sub-menu {
  @extend .bxs;
  position: absolute;
  right: 0;
  display: none;
  margin-top: 15px;
  padding: 15px;
  width: 220px;
  border-radius: 6px;
  background: #fff;
  animation: fadeIn 0.3s;

  &:before {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 40px;
    background: transparent;
    content: '';
  }

  &__item {
    display: block;
  }

  &__link {
    @include efekt(color);
    display: block;
    padding: 5px 0;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;

    &:before {
      position: relative;
      top: -3px;
      display: inline-block;
      margin-right: 6px;
      color: $colorOrange;
      content: '⬤';
      font-size: 4px;
    }

    &:hover {
      color: $colorOrange;
    }
  }
}

.has-sub:hover {
  .sub-menu {
    display: block;
  }
}

.has-sub:hover > .menu__link {
  color: $colorOrange;

  &:after {
    opacity: 1;
  }
}

.home-slider {
  position: relative;

  .swiper-pagination-progressbar {
    top: auto;
    bottom: 0;
    left: 0;
  }

  .swiper-slide {
    position: relative;
  }
}

.slider-mobile {
  display: none;
}

.slider-detail {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 5%;
  width: 100%;
  height: 100%;
  background: linear-gradient(55.35deg, rgba(3, 3, 3, 0.6) 17.37%, rgba(3, 3, 3, 0) 51.3%);
  color: #fff;

  &__title {
    font-weight: 900;
    font-size: 4.5vw;
    line-height: 1.2;

    small {
      display: block;
      font-weight: 300;
      font-size: 40%;
    }
  }

  &__text {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    font-size: 1.1rem;
  }

  &__link {
    @include efekt(color);
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;

    .bi {
      margin-right: 4px;
      width: 32px;
      height: 32px;
    }

    &:Hover {
      color: $colorOrange;
    }
  }

  &--page {
    padding-bottom: 30px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 30%);

    .slider-detail__title {
      font-size: 3vw;
    }

    .slider-detail__text {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 1rem;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  @extend .efekt;

  &:hover {
    color: $colorWedge;
    text-shadow: 1px 1px 6px $colorBlack-4;
  }
}

.site-card {
  @extend .bdrs;
  @include efekt(margin-top);
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-in-out;
    object-fit: cover;
  }

  a {
    position: relative;
    z-index: 1;
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &__title {
    @include efekt(background);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    display: flex;
    align-items: flex-end;
    padding: 30px 15px 15px;
    width: 100%;
    background: linear-gradient(transparent, $colorBlack-4);
    color: #fff;
    font-weight: 600;
    font-size: 1.4rem;

    svg {
      position: relative;
      top: -2px;
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  &--place {
    &:hover {
      margin-top: -10px;
    }
  }

  &--fullHeight {
    height: 100%;

    a {
      height: 100%;
    }
  }
}

.top-sites-slider {
  .swiper-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 100px;
    height: 100%;
    background: linear-gradient(-90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
  }

  .swiper-button-next,
  .swiper-button-prev {
    z-index: 5;
  }
}

.text {
  display: block;
  line-height: 1.7;

  &--bigger {
    font-size: 1.1rem;
  }

  p {
    display: block;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $colorOrange;
    text-decoration: underline;

    &:hover {
      color: $colorWedge;
    }
  }
}

.home-culture {
  padding: 70px 0 40px;
}

.home-about {
  margin-top: 100px;
  margin-bottom: 150px;

  &--flex {
    @extend .flex;
  }

  &--page {
    margin-bottom: 10%;
  }

  &__img {
    position: relative;
    width: 45%;
  }

  &__big {
    @extend .bdrs;
    @extend .bxs;
  }

  &__flower {
    position: absolute;
    right: -15%;
    bottom: -10%;
  }

  &__info {
    width: 50%;
  }
}

.footer-social {
  position: relative;
  margin-top: 150px;
  padding-top: 17%;
  padding-bottom: 100px;

  &__divider {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__title {
    color: #fff;
    text-align: center;
    font-size: 5vw;

    small {
      display: block;
      font-weight: 300;
      font-size: 50%;
    }
  }
}

.footer {
  background: #000;

  &__top {
    @extend .flex;
    padding: 20px 0;
  }

  &__bottom {
    @extend .flex;
    margin-top: 15px;
    padding: 15px 0;
    border-top: 1px solid $colorWhite;
    color: #a7a7a7;
    font-size: 0.8rem;
  }
}

.footer-menu {
  &__list {
    @extend .flex;
  }

  &__item {
    margin-left: 25px;
  }

  &__link {
    @include efekt(color);
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8rem;

    &:hover {
      color: $colorOrange;
    }
  }
}

.footer-links {
  a {
    display: inline-block;
    margin-right: 15px;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.line-title {
  margin-top: 30px;
  padding-top: 10%;
  padding-left: 30px;
  border-left: 1px solid $colorWedge;
}

.explore-titles {
  padding: 0 30px;
}

.article-box {
  padding: 70px 0;

  &--sticky {
    @extend .flex;
    align-items: flex-start;

    .article-box__content {
      width: 55%;
    }

    .text {
      overflow: visible;
      max-height: inherit;
    }
  }

  &__img {
    img {
      @extend .bdrs;
      display: block;
      margin-bottom: 5px;
      width: 100%;
    }
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    color: $colorWedge;
    font-weight: 700;
    font-size: 1.6rem;
  }
}

.photo-comment {
  font-size: 0.8rem;
  line-height: 1.2;
}

.article-page {
  .article-box {
    .text {
      overflow-y: auto;
      padding-right: 15px;
      max-height: 400px;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 2px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        border: 1px solid $colorBlack;
        border-radius: 10px;
        background-color: $colorBlack;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $colorBlack;
      }
    }

    .row {
      align-items: center;
    }

    &:nth-of-type(even) {
      background: #f4f4f4;

      .row {
        flex-direction: row-reverse;
      }
    }
  }
}

.sticky-image {
  position: sticky;
  top: 30%;
  width: 40%;
}

.article-navigation {
  @extend .flex;

  &__item {
    @include efekt(color);
    color: $colorWedge;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;

    small {
      display: block;
      color: #AFAFAF;
      font-weight: 300;
      font-size: 0.9rem;
    }

    &--prev {
      text-align: right;
    }

    &--next {}

    .bi {
      width: 24px;
      height: 24px;
    }

    &:hover {
      color: $colorOrange;
    }
  }
}

.st-btn {
  @extend .bxs;
  display: none !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  line-height: 50px !important;

  &:last-child {
    display: inline-block !important;
  }

  img {
    width: 100% !important;
    height: auto !important;
  }
}

.share-wrapper {
  .container {
    position: relative;
  }
}

#st-1 {
  position: absolute;
  top: -25px;
  right: 15px;
  z-index: 3 !important;
}

.banner {
  height: 250px;
}

hr {
  height: 1px;
  border: none;
  background: #dfdfdf;
}

.restaurant-card {
  @extend .bdrs;
  @extend .bxs;
  overflow: hidden;
  padding: 30px 30px 0;

  &__zoom {
    @extend .bdrs;
    @extend .efekt;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $colorBlack-4;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 1.3rem;
    opacity: 0;
  }

  &__img {
    position: relative;
    margin-bottom: 15px;

    img {
      @extend .bdrs;
    }
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    color: $colorWedge;
    font-weight: 700;
    font-size: 1.2rem;
  }

  &__rating {
    display: block;
    margin-bottom: 8px;
    color: #828282;
    font-size: 0.9rem;

    svg {
      color: #ffb800;
    }
  }

  &__text {
    display: block;
    margin-bottom: 30px;
    color: #3c3c3c;
    font-weight: 500;
    font-size: 1rem;
  }

  &__address {
    display: block;
    color: #5b5b5b;
    font-size: 0.9rem;

    strong {
      display: block;
    }
  }

  &__buttons {
    @extend .flex;
    margin-top: 30px;
    margin-right: -31px;
    margin-left: -30px;
    border-top: 1px solid #dfdfdf;
  }

  &__link {
    color: $colorOrange;
    font-size: 0.9rem;

    &:hover {
      color: $colorWedge;
    }
  }

  &__button {
    @extend .efekt;
    flex: 1;
    padding: 15px 0;
    border-right: 1px solid #dfdfdf;
    text-align: center;
    text-decoration: none;

    &:hover {
      background: $colorWedge;
      color: #fff;
    }

    &:last-child {
      border-right: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    &--phone {
      color: $colorWedge;
    }

    &--map {
      color: $colorOrange;
    }

    &--web {
      color: #48B749;
    }
  }

  &:hover {
    .restaurant-card__zoom {
      opacity: 1;
    }
  }
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  li {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
    padding-right: 30px;
    border-right: 1px solid #dfdfdf;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }

  svg {
    width: 40px;
    height: 40px;
    color: $colorOrange;
  }

  &__title {
    display: block;
    margin-top: 15px;
    color: $colorWedge;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2;
  }
}

.sticky-articles {
  .article-box {}
}

.hidden-img {
  position: absolute;
  z-index: -9999;
  visibility: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.sticky-article {
  min-height: 100vh;

  .article-box__img {
    display: none;
  }
}

.sticky {
  @extend .flex;
  align-items: flex-start;

  &__left {
    width: 55%;
  }

  &__img {
    position: sticky;
    top: 30%;
    width: 40%;
  }
}

.new-content {
  &__img {
    margin-bottom: 30px;

    img {
      width: 100%;
    }
  }

  &--first {
    .new-content__img {
      float: left;
      margin-right: 30px;
      width: 40%;
    }
  }
}

.new-content-articles {
  .new-content--big {
    padding: 80px 0;

    &:nth-of-type(even) {
      background: rgba(#C8FFE4, .2);
    }
  }
}

.gallery-img {
  @extend .bdrs;
  position: relative;
  overflow: hidden;

  &__overlay {
    @include efekt(opacity);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $colorBlack-3;
    color: #fff;
    opacity: 0;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  &:hover {
    .gallery-img__overlay {
      opacity: 1;
    }
  }
}

.home-explore {}

.explore {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__nav {
    width: 360px;

    .tab-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .tab-link {
      @extend .bdrs;
      @extend .efekt;
      display: inline-block;
      margin: 2%;
      padding: 15px;
      width: 46%;
      border: 1px solid transparent;
      box-shadow: 0 11px 24px -1px rgba(0, 0, 0, 0.09);
      color: inherit;
      text-align: center;
      text-decoration: none;

      &.active,
      &:Hover {
        border-color: $colorWedge;
        box-shadow: 0 15px 24px -1px rgba(0, 0, 0, 0.2);
        color: $colorWedge;
      }
    }
  }

  &__title {
    display: block;
    margin-top: 15px;
    width: 100%;
  }

  &__icon {
    height: 70px;
    object-fit: contain;
  }

  &__article {
    @extend .bdrs;
    padding: 30px;
    width: calc(100% - 390px);
    box-shadow: 0 11px 24px -1px rgba(0, 0, 0, 0.09);
  }

  &__img {
    display: block;
    margin-bottom: 30px;

    img {
      @extend .bdrs;
    }
  }
}

.header-spacing {
  margin-top: 150px;
}

.fresco-img {
  display: none;
}

.route {
  &__top {
    @extend .flex;
  }

  &__title {
    font-size: 1.4rem;

    img {
      position: relative;
      top: -2px;
      margin-right: 5px;
    }

    span {
      display: inline-block;
      margin-left: 10px;
    }
  }

  &__detail {
    font-size: 1.2rem;
  }

  &__number {
    display: inline-block;
    margin-left: 15px;

    svg {
      margin-right: 6px;
      width: 18px;
      height: 18px;
      color: $colorOrange;
    }
  }

  &__card {
    @extend .bxs;
    @extend .bdrs;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px;
    background: #fff;

    .text {
      width: calc(100% - 180px);
    }
  }
}

.travel {
  @extend .bdrs;
  position: relative;
  padding: 15px;
  width: 150px;
  background: #0C80C7;
  color: #fff;

  &:before {
    position: absolute;
    top: 20px;
    left: 24px;
    width: 1px;
    height: calc(100% - 40px);
    border-left: 1px dashed #fff;
    content: '';
  }

  &__title {
    position: relative;
    z-index: 2;
    padding: 5px 0 5px 25px;
    background-color: #0C80C7;
    background-image: url("../../dist/img/icon-map.svg");
    background-position: left center;
    background-size: 19px;
    background-repeat: no-repeat;
    font-weight: 500;
  }

  &__info {
    position: relative;
    z-index: 2;
    display: block;
    padding: 15px 0;
    text-align: center;
    font-size: 0.8rem;
    font-size: 0.9rem;

    img {
      width: 30px;
      display: block;
      margin: 0 auto 5px;
    }
  }
}

.route-table {
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    border-radius: 6px;
    overflow: hidden;
    @extend .bxs;
    font-size: 0.9rem;
  }

  thead {
    tr {
      border: none;
    }
  }

  th {
    font-size: 1rem;
    background: $colorOrange;
    color: #fff;
  }

  tr {
    border-bottom: 1px solid #dfdfdf;

    &:last-child {
      border: none;
    }
  }

  td,
  th {
    padding: 12px 15px;
    vertical-align: middle;
  }

  .right-border {
    border-right: 1px solid #dfdfdf;
    font-size: 1rem;
    font-weight: 600;
    width: 200px;
  }
}

.blog {
  background: #fff;
  @extend .bdrs;
  @extend .bxs;
  padding: 20px;
  position: relative;
  padding-bottom: 70px;
  @extend .efekt;
  height: 100%;

  &:Hover {
    @extend .bxsh;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &__img {
    margin-bottom: 15px;

    img {
      @extend .bdrs;
    }
  }

  &__title {
    color: $colorWedge;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
    font-size: 1.2rem;
  }

  &__text {
    font-size: 0.8rem;
    color: #575757;
    line-height: 1.6;
    display: block;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    @extend .flex;
    width: 100%;
    font-size: 0.9rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  &__link {
    color: $colorWedge;
    font-weight: 500;
  }

  &__date {
    color: #B5B5B5;
  }
}

.paging {
  @extend .flex;
  justify-content: center;

  &__item {
    margin: 5px;
  }

  &__link {
    padding: 12px 15px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    color: #9C9C9C;
    font-weight: 500;
    font-size: 0.9rem;
    display: inline-block;
    text-decoration: none;
    @extend .efekt;

    &.active,
    &:Hover {
      background: $colorWedge;
      color: #fff;
      border-color: $colorWedge;
    }
  }
}

.blog-detail-nav {
  @extend .flex;
  font-size: 0.8rem;
}

.blog-title {
  font-size: 1.5rem;
  font-weight: 500;
  display: block;
  color: $colorWedge;

  &--big {
    font-size: 1.3rem;
    font-weight: 400;
    color: #575757;
    margin-bottom: 15px;
  }
}

.blog-image {
  float: left;
  width: 35%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.testimonial {
  background: #fff;
  @extend .bxs;
  padding: 30px;

  &__figure {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  &__text {
    font-size: 0.9rem;
    text-align: center;
    display: block;
    margin-bottom: 15px;
  }

  &__quote {
    display: block;
    text-align: center;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  &__name {
    display: block;
    margin-top: 15px;
    text-align: center;

    span {
      display: block;
      color: $colorWedge;
      font-weight: 600;
    }

    small {
      font-size: 0.9rem;
    }
  }
}

.day-list {
  position: relative;
  font-size: 0.95rem;

  &:before {
    position: absolute;
    top: 4px;
    left: 80px;
    width: 1px;
    height: calc(100% - 16px);
    background: #dfdfdf;
    content: '';
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 1.1rem;
  }

  &__item {
    position: relative;
    margin: 15px 0 0;
    padding-left: 120px;
  }

  &__number {
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 110px;
    font-weight: 400;

    &:before {
      position: absolute;
      top: 3px;
      left: 75px;
      z-index: 3;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: $colorWedge;
      content: '';
    }
  }

  &__text {
    display: block;
    font-size: 0.9rem;

    small {
      display: inline-block;
      color: #a1a1a1;
      font-weight: 300;

      &:before {
        margin: 0 8px;
        content: '|';
      }
    }
  }

  &__subtitle{
    font-weight: 500;
  }

  &__detail {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-top: 2px;
    color: #565656;
    font-weight: 300;

    .icon{
      margin-right: 15px;
      display: inline-block;
      color: $colorWedge;
    }

    svg{
      width: 36px;
      vertical-align: middle;
    }

    span {
      display: block;
      font-weight: 500;
    }

    .link {
      margin-top: 5px;
      font-size: 1rem;
    }
  }

  .text {
    display: block;
    margin-bottom: 30px;
  }

  &__tags{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 15px 0;

    li{
      display: inline-block;
      padding: 6px 16px;
      padding-bottom: 4px;
      border-radius: 32px;
      border: 1px solid #dfdfdf;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: .9rem;
      font-weight: 400;
      color: #707070;
    }
  }

  &--extended {
    border-bottom: 1px solid #dfdfdf;

    &:before {
      height: calc(100% - 4px);
    }

    .day-list__item {
      padding-bottom: 70px;
    }
  }

  &--bordered {
    .day-list__item {
      &:first-child {
        .day-list__text {
          padding-top: 0;

          &:before {
            display: none;
          }
        }
      }
    }

    .day-list__text {
      position: relative;
      padding-top: 30px;

      &:before {
        position: absolute;
        top: 8px;
        left: -20px;
        width: 100%;
        height: 1px;
        background: #dfdfdf;
        content: '';
      }
    }
  }
}
@import 'style1500', 'style1200', 'style992', 'mobil-nav', 'style768', 'style576';
