@media (max-width: 768px) {
  .slider-mobile {
    display: block;
  }

  .slider-desktop {
    display: none;
  }

  .home-slider {
    .swiper-slide {
      img {
        height: auto;
      }
    }
  }

  .logo {
    img {
      width: 80px;
    }
  }

  .slider-detail__text {
    margin: 15px 0;
    width: 100%;
  }

  .slider-detail__title {
    margin-bottom: 15px;
    font-size: 7.5vw;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .mt75 {
    margin-top: 40px;
  }

  .mb75 {
    margin-bottom: 40px;
  }

  .text {
    font-size: 0.9rem;
  }

  .home-culture {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .home-about--flex {
    align-items: flex-start;
  }

  .btn {
    font-size: 0.8rem;
  }

  .footer-social {
    margin-top: 100px;
    padding-bottom: 30px;
  }

  .footer-social__title {
    font-size: 7vw;
  }

  .page-slider {
    .swiper-slide {
      img {
        height: 350px;
      }
    }
  }

  .slider-detail--page .slider-detail__title {
    font-size: 5vw;
  }

  .slider-detail--page {
    .slider-detail__text {
      display: block;
    }
  }

  .explore-titles {
    .site-card__title {
      font-size: 1rem;

      svg {
        top: -4px;
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .article-box {
    padding: 30px 0;
  }

  .article-box__title {
    font-size: 1.4rem;
  }

  .photo-comment {
    font-size: 0.7rem;
  }

  .article-navigation {}

  .article-navigation__item {
    margin-bottom: 20px;
    width: 90%;
    font-size: 0.9rem;

    &--prev {
      margin-right: auto;
      text-align: left;
    }

    &--next {
      margin-left: auto;
      text-align: right;
    }

    small {
      font-size: 0.7rem;
    }

    .bi {
      width: 20px;
      height: 20px;
    }
  }

  .sticky-article .article-box__img {
    display: block;
  }

  .sticky__img {
    display: none;
  }

  .sticky__left {
    width: 100%;
  }

  .sticky-article {
    padding: 50px 0;
    min-height: inherit;
    border-bottom: 1px solid #dfdfdf;
  }

  .new-content--first {
    .new-content__img {
      width: 100%;
      float: none;
      margin-right: 0;
    }
  }

  .new-content-articles .new-content--big {
    padding: 30px 0;
  }

  .header-spacing {
    margin-top: 120px;
  }

  .route__title {
    span {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .travel__title {
    font-size: 0.8rem;
  }

  .travel__info {
    font-size: 0.8rem;

    img {
      width: 20px;
    }
  }

  .travel {
    width: 120px;
  }

  .route__card .text {
    width: calc(100% - 150px);
  }

  .route-table .right-border {
    font-size: 0.8rem;
    width: auto;
  }

  .route-table table {
    font-size: 0.8rem;

    th {
      font-size: 0.8rem;
    }
  }

  .route-table td,
  .route-table th {
    padding: 10px;
  }

  .paging__link {
    padding: 8px 12px 4px;
    font-size: 0.8rem;
  }

  .blog-title {
    font-size: 1.1rem;

    &--big {
      font-size: 1rem;
    }
  }

  .day-list__number:before {
    left: 40px;
    width: 7px;
    height: 7px;
  }

  .day-list__number {
    font-size: 0.7rem;
    width: 50px;
  }

  .day-list__item {
    padding-left: 55px;
  }

  .day-list:before {
    left: 43px;
  }

  .day-list .text {
    margin-bottom: 15px;
    font-size: 0.9rem;
  }

  .day-list--extended .day-list__item {
    padding-bottom: 30px;
  }

  .day-list__title{
    font-size: .9rem;
  }
}
