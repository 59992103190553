@media(max-width:1600px) {
  .slider-detail {
    padding-left: 5%;
    width: 95%;
  }

  .slider-detail {
    .container {
      max-width: 100%;
    }
  }
}
@media(max-width:1500px) {
  .header {
    padding-left: 15px;
  }

  .logo {
    img {
      width: 116px;
    }
  }

  .menu {
    padding-right: 15px;
  }

  .menu__item {
    margin-left: 15px;
  }

  .menu__link {
    padding: 32px 0;
    font-size: 0.8rem;
  }

  .footer-menu__item {
    margin-left: 20px;
  }

  .sub-menu__link {
    font-size: 0.8rem;
  }

  .sub-menu {
    width: 200px;
  }
}
