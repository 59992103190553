@media (max-width: 576px) {
  .slider-detail__text {
    display: none;
  }

  .top-sites-slider:after,
  .top-sites-slider:before {
    display: none;
  }

  .top-sites-slider {
    padding-left: 5px;
  }

  .home-culture {
    .site-card {
      img {
        height: 20vh;
      }
    }
  }

  .home-about--flex {
    justify-content: center;
  }

  .home-about__img {
    margin-bottom: 50px;
    width: 250px;
  }

  .home-about__info {
    width: 100%;
    text-align: center;
  }

  .footer-social {
    margin-top: 50px;
  }

  .footer__bottom {
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: center;
  }

  .copyright {
    width: 100%;
  }

  .footer-links {
    display: block;
    width: 100%;
    text-align: center;

    a {
      margin: 10px;
    }
  }

  .explore-titles {
    padding: 0 8px;

    .mb30 {
      margin-bottom: 8px;
    }

    .row {
      margin-right: -4px;
      margin-left: -4px;
    }

    .col-6 {
      padding-right: 4px;
      padding-left: 4px;
    }

    .site-card__title {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: 100%;
      text-align: center;
      font-size: 0.8rem;
      line-height: 1.2;

      svg {
        top: auto;
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        height: 25px;
      }
    }
  }

  .page-slider .swiper-slide img {
    height: 270px;
  }

  .icon-list li {
    width: 46%;
  }

  .interest-grid{

    .site-card {
      img {
        height: 150px;
      }
    }

    .row{
      margin-left: -5px;
      margin-right: -5px;
    }
    .item{
      padding-left: 5px;
      padding-right: 5px;

      &:last-child{
        .site-card{
          img{
            height: 130px;
          }
        }
      }
    }

    .mb30{
      margin-bottom: 10px;
    }

    .site-card__title{
      padding: 5px;
      font-size: 3.7vw;
    }
  }

  .home-culture{
    .site-card__title{
      font-size: .9rem;
      padding: 15px;
    }
  }

  .route__card{
    padding: 15px;
  }

  .travel{
    width: 110px;
    position: sticky;
    top: 88px;
  }

  .route__card .text{
    width: calc(100% - 125px);
  }

  .route-table{
    overflow-x:scroll;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 8px 22px -6px rgba(0, 0, 0, .1), 0 8px 22px -6px rgba(0, 0, 0, .1);
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $colorBlack-2;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $colorOrange;
    }

    table{
      width: 660px;
      box-shadow: none;
    }
  }

  .route-table .right-border{
    width: 150px;
  }

  .blog-image{
    margin-right: 0;
    float: none;
    width: 100%;
  }

  .blog-detail-nav__info{
    width: 100%;
    margin-bottom: 15px;  
  }
}
